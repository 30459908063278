import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  Alert,
  InputAdornment,
  IconButton,
  Divider,
  Switch,
  FormControlLabel,
  Link,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Email, Lock, Phone, Person } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { colors } from '../themes/colors';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gender, setGender] = useState('Male');
  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validatePassword = (password) => password.length >= 6;
  const validateName = (name) => name.length >= 2;
  const validatePhone = (phone) => /^[0-9]{10}$/.test(phone);

  const onChangeNameField = (e) => {
    const text = e.target.value;
    setName(text);
    setValidName(!validateName(text));
  };

  const onChangeEmailField = (e) => {
    const text = e.target.value;
    setEmail(text);
    setValidEmail(!validateEmail(text));
  };

  const onChangePasswordField = (e) => {
    const text = e.target.value;
    setPassword(text);
    setValidPass(!validatePassword(text));
  };

  const onChangePhoneField = (e) => {
    const text = e.target.value;
    setMobileNumber(text);
    setValidPhone(!validatePhone(text));
  };

  const handleGenderSwitch = (e) => {
    setGender(e.target.checked ? 'Female' : 'Male');
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignUp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}registration`, {
        name: name,
        email: email,
        password: password,
        phoneNumber: mobileNumber,
        gender: gender,
      });
      setLoading(false);
      localStorage.setItem('activation_token',response.data.activationToken);
      navigate('/otpverification'); // Redirect to otp verification page after successful sign-up
    } catch (error) {
      console.error('Sign up failed', error);
      setError('Sign up failed. Please try again.');
      setLoading(false);
    }
  };

  const onPressSignUpButton = () => {
    if (
      validateEmail(email) &&
      validatePassword(password) &&
      validateName(name) &&
      validatePhone(mobileNumber)
    ) {
      handleSignUp();
    } else {
      setValidName(!validateName(name));
      setValidEmail(!validateEmail(email));
      setValidPass(!validatePassword(password));
      setValidPhone(!validatePhone(mobileNumber));
    }
  };

  return (
    <Container maxWidth="xs">
      <Box mt={8} p={3} boxShadow={3} borderRadius={2}>
        <Typography variant="h4" align="center" gutterBottom>
          Sign Up
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
            {error}
          </Alert>
        )}
        <TextField
          label="Full Name"
          value={name}
          onChange={onChangeNameField}
          fullWidth
          margin="normal"
          variant="outlined"
          error={validName}
          helperText={validName ? 'Please enter a valid name' : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
          disabled={loading}
        />
        <TextField
          label="Email"
          value={email}
          onChange={onChangeEmailField}
          fullWidth
          margin="normal"
          variant="outlined"
          error={validEmail}
          helperText={validEmail ? 'Please enter a valid email' : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
          disabled={loading}
        />
        <TextField
          label="Phone Number"
          value={mobileNumber}
          onChange={onChangePhoneField}
          fullWidth
          margin="normal"
          variant="outlined"
          error={validPhone}
          helperText={validPhone ? 'Please enter a valid phone number' : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            ),
          }}
          disabled={loading}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={onChangePasswordField}
          fullWidth
          margin="normal"
          variant="outlined"
          error={validPass}
          helperText={validPass ? 'Password must be at least 6 characters' : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end" disabled={loading}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={loading}
        />
        <FormControlLabel
          control={<Switch checked={gender === 'Female'} onChange={handleGenderSwitch} />}
          label={`Gender: ${gender}`}
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onPressSignUpButton}
          sx={{ mt: 2 }}
          disabled={loading}
        >
          {loading ? 'Signing Up...' : 'Sign Up'}
        </Button>
        <Divider sx={{ my: 2 }} />
        <Grid container justifyContent="center">
          <Grid item>
            <Link onClick={() => navigate('/signin')} variant="body2" sx={{ cursor: 'pointer' }}>
              {"Already have an account? Sign In"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SignUp;
