import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    CssBaseline,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Avatar,
    Collapse,
    Divider,
    IconButton,
    TextField,
    InputAdornment,
    Badge,
    useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import StoreIcon from '@mui/icons-material/Store';
import axios from 'axios';
import DashboardOverview from './DashboardOverview';
import CreateCategory from './CreateCategory';
import CreateBusiness from './CreateBusiness';
import NotificationDropdown from './NotificationDropdown';
import { colors } from '../themes/colors';
import GetBusinesses from './GetBusiness';

const drawerWidth = 240;

const Dashboard = () => {
    const [openBusinessMenu, setOpenBusinessMenu] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useState({ name: '', role: '' });

    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
        if (loggedInUser) {
            setUser({ name: loggedInUser.name, role: loggedInUser.role });
        }
    }, []);

    const toggleBusinessMenu = () => {
        setOpenBusinessMenu(!openBusinessMenu);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleNotificationClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationClose = () => {
        setAnchorEl(null);
    };

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    const handleLogout = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}logout`, {
                headers: {
                    'access-token': localStorage.getItem('access_token'),
                },
            });

            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('loggedInUser');

            navigate('/');
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    zIndex: theme.zIndex.drawer + 1,
                    background: 'linear-gradient(to right, #681C7C, #9141AC)',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '0 0 20px 20px',
                }}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                        Refreshly
                    </Typography>
                    {/* <TextField
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '50px',
                            mr: 2,
                            width: 300,
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#681C7C',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#9141AC',
                                },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: '#681C7C' }} />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                    <IconButton color="inherit" sx={{ mr: 2 }} onClick={handleNotificationClick}>
                        <Badge badgeContent={4} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    {/* <Avatar
                        alt={user.name}
                        src="https://via.placeholder.com/150"
                        sx={{
                            width: 40,
                            height: 40,
                            ml: 2,
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    /> */}
                    <Button variant="text" color="inherit" onClick={handleLogout} sx={{ ml: 2 }}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="persistent"
                open={drawerOpen}
                sx={{
                    width: drawerOpen ? drawerWidth : theme.spacing(7) + 1,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerOpen ? drawerWidth : theme.spacing(7) + 1,
                        boxSizing: 'border-box',
                        background: 'linear-gradient(to bottom, #ffffff, #f9f9f9)',
                        color: '#333',
                        boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '0 20px 20px 0',
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ textAlign: 'center', padding: 2 }}>
                    <Avatar
                        alt={user.name}
                        src="https://via.placeholder.com/150"
                        sx={{
                            width: 80,
                            height: 80,
                            marginBottom: 1,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                        }}
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#681C7C' }}>
                        {user.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {user.role}
                    </Typography>
                </Box>
                <Divider />
                <List>
                    <ListItem button component={Link} to="overview" selected={isActive('overview')}>
                        <ListItemIcon sx={{ color: '#681C7C' }}>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Dashboard"
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                color: '#681C7C',
                            }}
                        />
                    </ListItem>
                    <ListItem button onClick={toggleBusinessMenu} selected={isActive('create-category') || isActive('create-business')}>
                        <ListItemIcon sx={{ color: '#681C7C' }}>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Business"
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                color: '#681C7C',
                            }}
                        />
                        {openBusinessMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={openBusinessMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                component={Link}
                                to="create-category"
                                sx={{ pl: 4 }}
                                selected={isActive('create-category')}
                            >
                                <ListItemIcon sx={{ color: '#681C7C' }}>
                                    <CategoryIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Create Category"
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        color: '#681C7C',
                                    }}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component={Link}
                                to="create-business"
                                sx={{ pl: 4 }}
                                selected={isActive('create-business')}
                            >
                                <ListItemIcon sx={{ color: '#681C7C' }}>
                                    <StoreIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Create Business"
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        color: '#681C7C',
                                    }}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component={Link}
                                to="get-business"
                                sx={{ pl: 4 }}
                                selected={isActive('get-business')}
                            >
                                <ListItemIcon sx={{ color: '#681C7C' }}>
                                    <StoreIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Get Business"
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        color: '#681C7C',
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    transition: theme.transitions.create(['margin', 'width'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${theme.spacing(7) + 1}px)`,
              
                    borderRadius: '20px',
             
                }}
            >
                <Toolbar />
                <Routes>
                    <Route path="/" element={<Navigate to="overview" />} />
                    <Route path="overview" element={<DashboardOverview />} />
                    <Route path="create-category" element={<CreateCategory />} />
                    <Route path="create-business" element={<CreateBusiness />} />
                    <Route path="get-business" element={<GetBusinesses />} />
                    <Route path="edit-business" element={<GetBusinesses />} />
                </Routes>
            </Box>

            <NotificationDropdown anchorEl={anchorEl} onClose={handleNotificationClose} />
        </Box>
    );
};

export default Dashboard;
