import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const BusinessApiSlice = createApi({
  reducerPath: 'businessApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_SERVER_URL}` }),
  endpoints: (builder) => ({
    createBusiness: builder.mutation({
      query: (newBusiness) => ({
        url: 'upload-business',
        method: 'POST',
        body: newBusiness,
      }),
    }),
    getCategories: builder.query({
      query: () => 'get-all-categories',
    }),
    getBusinesses: builder.query({
      query: () => 'get-all-business',
    }),
  }),
});

export const { useCreateBusinessMutation, useGetCategoriesQuery, useGetBusinessesQuery } = BusinessApiSlice;
