// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { BusinessApiSlice } from './features/Business/BusinessApiSlice'; // Import the API slice

export const store = configureStore({
  reducer: {
    // Add the API slice reducer
    [BusinessApiSlice.reducerPath]: BusinessApiSlice.reducer,
    // Add other reducers here if you have any
  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(BusinessApiSlice.middleware),
});

export default store;
