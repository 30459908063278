import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  Alert,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OTPVerification = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const code = localStorage.getItem('activation_token');
    if (code) {
      setActivationCode(code);
    } else {
      setError('No activation code found. Please register again.');
    }
  }, []);

  const validateOtp = (otp) => /^\d{4}$/.test(otp); // Validates a 4-digit numeric OTP

  const onChangeOtpField = (e) => {
    const value = e.target.value;
    setOtp(value);
    if (value.length === 4 && validateOtp(value)) {
      setError('');  // Clear any error message if OTP is valid
    } else {
      setError('Please enter a valid 4-digit OTP.');
    }
  };

  const handleVerifyOtp = async () => {
    setError(''); // Clear any previous errors
    if (!validateOtp(otp)) {
      setError('Please enter a valid 4-digit OTP.');
      return;
    }

    setLoading(true);

    if (otp.length === 4 && activationCode) {
      try {
        // Send the OTP to the server for verification
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}activate-user`, {
          activation_token: activationCode,
          activation_code: otp,
        });

        if (response.data.success) { // Adjust this check based on your API response structure
          setLoading(false);
          navigate('/signin'); // Redirect to the sign-in page after successful OTP verification
        } else {
          setLoading(false);
          setError('OTP verification failed. Please try again.');
        }
      } catch (error) {
        console.error('OTP verification failed', error);
        setError('OTP verification failed. Please try again.');
        setLoading(false);
      }
    } else {
      setError('Activation code or OTP is invalid.');
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box mt={8} p={3} boxShadow={3} borderRadius={2}>
        <Typography variant="h4" align="center" gutterBottom>
          OTP Verification
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Enter the 4-digit code sent to your registered email or phone number.
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
            {error}
          </Alert>
        )}
        <TextField
          label="OTP"
          value={otp}
          onChange={onChangeOtpField}
          fullWidth
          margin="normal"
          variant="outlined"
          error={!!error}
          helperText={error ? error : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="h6">🔒</Typography>
              </InputAdornment>
            ),
            inputProps: { maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*' },
          }}
          disabled={loading}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleVerifyOtp}
          sx={{ mt: 2 }}
          disabled={loading || otp.length !== 4 || !!error}
        >
          {loading ? 'Verifying...' : 'Verify OTP'}
        </Button>
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
          <Grid item>
            <Typography variant="body2">
              Didn't receive the code? <Button variant="text">Resend</Button>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default OTPVerification;
