import React from 'react';
import {
    Menu,
    MenuItem,
    ListItemText,
    Typography,
    IconButton,
    Badge,
    Divider,
    Box,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

const notifications = [
    { id: 1, text: "New user registered" },
    { id: 2, text: "Server downtime reported" },
    { id: 3, text: "New order received" },
    { id: 4, text: "Product out of stock" },
    { id: 5, text: "New comment on your post" },
    { id: 6, text: "Password change requested" },
    // Add more notifications as needed
];

const NotificationDropdown = ({ anchorEl, onClose }) => {
    const open = Boolean(anchorEl);

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    maxHeight: 400,
                    width: '300px',
                    padding: 0,
                },
            }}
        >
            <Box sx={{ padding: 2 }}>
                <Typography variant="h6">Notifications</Typography>
            </Box>
            <Divider />
            {notifications.length > 0 ? (
                notifications.map((notification) => (
                    <MenuItem key={notification.id} onClick={onClose}>
                        <ListItemText primary={notification.text} />
                    </MenuItem>
                ))
            ) : (
                <MenuItem onClick={onClose}>
                    <ListItemText primary="No new notifications" />
                </MenuItem>
            )}
        </Menu>
    );
};

export default NotificationDropdown;
