import React, { useMemo, useRef, useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  CardMedia,
  Box,
  Tabs,
  Tab,
  Divider,
  Chip,
  Avatar,
  TextField,
  InputAdornment,
  Rating,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  useGetBusinessesQuery,
  useGetCategoriesQuery,
} from "../redux/features/Business/BusinessApiSlice";

const GetBusinesses = () => {
  const {
    data: businessesData,
    error: businessesError,
    isLoading: businessesLoading,
  } = useGetBusinessesQuery();
  const {
    data: categoriesData,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useGetCategoriesQuery();

  const businesses = businessesData?.businessInfo || [];
  const categories = categoriesData?.categories || [];
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedServiceTab, setSelectedServiceTab] = useState({});

  const businessRefs = useRef({});

  const mappedBusinesses = useMemo(() => {
    return businesses.map((business) => {
      const mappedCategories = business.optedCategories.map((obj) =>
        categories.find((category) => category._id === obj.categoryId)
      );
      return { ...business, mappedCategories };
    });
  }, [businesses, categories]);

  const handleEditClick = (businessId) => {
    console.log("Edit button Clicked...");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleServiceTabChange = (businessId, event, newValue) => {
    setSelectedServiceTab((prevState) => ({
      ...prevState,
      [businessId]: newValue,
    }));
  };

  const filteredBusinesses = useMemo(() => {
    if (!searchTerm) return mappedBusinesses;

    return mappedBusinesses.filter((business) => {
      const matchesBusinessName = business.name
        .toLowerCase()
        .includes(searchTerm);
      const matchesServiceName = business.services.some((service) =>
        service.name.toLowerCase().includes(searchTerm)
      );
      const matchesServiceDataName = business.services.some((service) =>
        service.businessServiceData.some((serviceData) =>
          serviceData.name.toLowerCase().includes(searchTerm)
        )
      );

      return (
        matchesBusinessName || matchesServiceName || matchesServiceDataName
      );
    });
  }, [searchTerm, mappedBusinesses]);

  const scrollToBusiness = (businessId) => {
    if (businessRefs.current[businessId]) {
      businessRefs.current[businessId].scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to the first matched business when the search term changes
  useEffect(() => {
    if (filteredBusinesses.length > 0 && searchTerm) {
      scrollToBusiness(filteredBusinesses[0]._id);
    }
  }, [searchTerm, filteredBusinesses]);

  if (businessesError || categoriesError) {
    return <Typography color="error">Failed to load data.</Typography>;
  }

  return (
    <Container maxWidth="lg">
      {/* Backdrop for loading */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={businessesLoading || categoriesLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ mt: 4, fontWeight: "bold", color: "#681C7C" }}
      >
        All Business Stores
      </Typography>

      <Box
        sx={{
          position: "sticky",
          top: 60,
          zIndex: 1000,
          backgroundColor: "#ffffff",
          pt: 2,
          pb: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          background: "linear-gradient(to right, #ffffff, #f7f7f7)",
          borderRadius: "50px",
          px: 2,
        }}
      >
        <TextField
          fullWidth
          label="Search businesses, services..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#681C7C" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "50px",
              background: "linear-gradient(to right, #ffffff, #f9f9f9)",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#681C7C",
            },
            "& .MuiInputBase-input": {
              color: "#681C7C",
            },
          }}
        />
      </Box>

      {filteredBusinesses.length > 0 ? (
        <Grid container spacing={4} direction="column" sx={{ mt: 2 }}>
          {filteredBusinesses.map((business) => (
            <Grid
              item
              xs={12}
              key={business._id}
              ref={(el) => (businessRefs.current[business._id] = el)}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  padding: 3,
                  position: "relative",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: 3,
                  background:
                    "linear-gradient(to bottom right, #ffffff, #f9f9f9)",
                  border: `1px solid #e0e0e0`,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                {/* Edit Button Overlay */}
                <Box sx={{ position: "absolute", top: 12, right: 12 }}>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => handleEditClick(business._id)}
                    variant="contained"
                    sx={{
                      background: "linear-gradient(to right, #681C7C, #9141AC)",
                      borderRadius: "50px",
                      padding: "6px 16px",
                      fontWeight: "bold",
                      boxShadow: "0px 4px 12px rgba(104, 28, 124, 0.4)",
                      "&:hover": {
                        background:
                          "linear-gradient(to right, #5A175F, #702D8C)",
                      },
                    }}
                  >
                    Edit
                  </Button>
                </Box>

                {business.thumbnailImage && business.thumbnailImage.url && (
                  <CardMedia
                    component="img"
                    image={`${process.env.REACT_APP_FILE_PATH}${business.thumbnailImage.url}`}
                    alt={`${business.name} Thumbnail`}
                    sx={{
                      width: { xs: "100%", md: 220 },
                      height: 220,
                      borderRadius: 2,
                      mb: { xs: 2, md: 0 },
                      mr: { md: 3 },
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                )}
                <Box sx={{ flex: 1 }}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "#681C7C", mb: 1 }}
                    >
                      {business.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ mb: 2 }}
                    >
                      {business.address}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Rating
                        name="read-only"
                        value={Number(business.rating)}
                        precision={0.1}
                        readOnly
                        sx={{ color: "#681C7C" }}
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ ml: 1 }}
                      >
                        {business.rating}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mb: 0.5 }}
                    >
                      <strong>Distance:</strong> {business.distance} km
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>{business.noOfPeopleRated}</strong> people rated
                    </Typography>

                    {/* Display Mapped Categories */}
                    {business.mappedCategories &&
                      business.mappedCategories.length > 0 && (
                        <>
                          <Typography
                            variant="subtitle1"
                            sx={{ mt: 3, fontWeight: "bold", color: "#681C7C" }}
                          >
                            Categories:
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              mt: 1,
                            }}
                          >
                            {business.mappedCategories.map((category) => (
                              <Chip
                                key={category._id}
                                label={category.name}
                                avatar={<Avatar src={category.image} />}
                                sx={{
                                  fontSize: "0.875rem",
                                  fontWeight: "500",
                                  backgroundColor: "#f1f1f1",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  color: "#681C7C",
                                }}
                              />
                            ))}
                          </Box>
                        </>
                      )}

                    {/* Display Services */}
                    {business.services && business.services.length > 0 && (
                      <>
                        <Typography
                          variant="subtitle1"
                          sx={{ mt: 3, fontWeight: "bold", color: "#681C7C" }}
                        >
                          Services:
                        </Typography>
                        <Tabs
                          value={selectedServiceTab[business._id] || 0}
                          onChange={(event, newValue) =>
                            handleServiceTabChange(business._id, event, newValue)
                          }
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          sx={{
                            mb: 2,
                            "& .MuiTab-root": {
                              fontWeight: "bold",
                              color: "#681C7C",
                              transition: "background-color 0.3s ease",
                              "&:hover": {
                                backgroundColor: "#f3e6f7",
                                borderRadius: "20px",
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#f9e6ff",
                                borderRadius: "20px",
                              },
                            },
                            "& .MuiTabs-indicator": {
                              height: 4,
                              borderRadius: "50px",
                              backgroundColor: "#681C7C",
                            },
                          }}
                        >
                          {business.services.map((service, index) => (
                            <Tab key={index} label={service.name} />
                          ))}
                        </Tabs>
                        {business.services.map((service, index) => (
                          <Box
                            key={index}
                            sx={{ mt: 2 }}
                            hidden={
                              business.services.length === 1
                                ? false
                                : selectedServiceTab[business._id] !== index
                            }
                          >
                            {service.businessServiceData.map(
                              (serviceData, subIndex) => (
                                <Box key={subIndex} sx={{ mb: 1 }}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    <strong>{serviceData.name}</strong> -{" "}
                                    {serviceData.price} (Offered:{" "}
                                    {serviceData.offeredPrice})
                                  </Typography>
                                </Box>
                              )
                            )}
                          </Box>
                        ))}
                      </>
                    )}
                  </CardContent>
                </Box>
                {/* Map View */}
                {business.latitude && business.longitude && (
                  <Box
                    sx={{
                      width: { xs: "100%", md: 320 },
                      height: 220,
                      mt: { xs: 2, md: 0 },
                      ml: { md: 3 },
                      borderRadius: 2,
                      overflow: "hidden",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src={`https://maps.google.com/maps?q=${business.latitude},${business.longitude}&z=15&output=embed`}
                      title={`${business.name} Location`}
                      style={{ borderRadius: "2px" }}
                    ></iframe>
                  </Box>
                )}
              </Card>
              <Divider sx={{ my: 4 }} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No businesses available.</Typography>
      )}
    </Container>
  );
};

export default GetBusinesses;
