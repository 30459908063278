import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/SplashScreen.css';
//import './SplashScreen.css'; // Import the CSS file

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/signin'); // Navigate to the dashboard after 3 seconds
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, [navigate]);

  return (
    <div className="splash-screen">
      <img
        src="https://res.cloudinary.com/dvtflyo1y/image/upload/v1723292221/App/logo_wt2p8n.png"
        alt="App Icon"
        className="splash-icon"
      />
    </div>
  );
};

export default SplashScreen;
