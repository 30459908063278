import React from 'react';
import { Typography } from '@mui/material';

const CreateCategory = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Create Category
      </Typography>
      <Typography variant="body1">
       Coming Soon.....
      </Typography>
    </div>
  );
};

export default CreateCategory;
