export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  primary: '#681C7C', //#6F45F0
  primaryLight: '#8C459C', //#C3BEFE
  primarySurface: '#F3E5F5',
  secondary: '#1ED390', //#1ED390
  secondaryLight: '#1ED390', //##1ED390
  secondarySurface: '#E7F1ED',
  grayText: '#737373',
  errorColor: '#FF0000',
  inputBorder: '#E1E1E1',
  textColor: '#262626',
  modalBackground: 'rgba(0,0,0,0.5)',
  tabIcon: '#6F6F6F',
  yellow: '#FFC60B',
  modalBg: '#00000099',
  red: '#FF5470',
  green: '#1ED390',
  lightGray: '#9F9F9F',
  containerWhite: '#f8f8ff',
};
