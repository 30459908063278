import React from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

// Sample data for the charts
const salesData = [
  { name: 'Jan', Sales: 4000, Visits: 2400 },
  { name: 'Feb', Sales: 3000, Visits: 2210 },
  { name: 'Mar', Sales: 2000, Visits: 2290 },
  { name: 'Apr', Sales: 2780, Visits: 2000 },
  { name: 'May', Sales: 1890, Visits: 2181 },
  { name: 'Jun', Sales: 2390, Visits: 2500 },
  { name: 'Jul', Sales: 3490, Visits: 2100 },
];

const pieData = [
  { name: 'Series 1', value: 400, color: '#4CAF50' },
  { name: 'Series 2', value: 300, color: '#FF9800' },
  { name: 'Series 3', value: 300, color: '#9C27B0' },
];

const DashboardOverview = () => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Welcome!
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 3,
              background: '#4CAF50',
              color: 'white',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Sales</Typography>
            <Typography variant="h4">15852</Typography>
            <Typography variant="body2">60% increase</Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 3,
              background: '#00BCD4',
              color: 'white',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Orders</Typography>
            <Typography variant="h4">956</Typography>
            <Typography variant="body2">90% increase</Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 3,
              background: '#9C27B0',
              color: 'white',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Visitors</Typography>
            <Typography variant="h4">20544</Typography>
            <Typography variant="body2">60% increase</Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 3,
              background: '#F44336',
              color: 'white',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Users</Typography>
            <Typography variant="h4">5210</Typography>
            <Typography variant="body2">57% increase</Typography>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Website Stats
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Sales" stroke="#4CAF50" strokeWidth={2} />
                  <Line type="monotone" dataKey="Visits" stroke="#00BCD4" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Website Stats
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={pieData}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardOverview;
