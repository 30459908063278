import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import Dashboard from './components/Dashboard';
import { Provider } from 'react-redux';
import Login from './components/SignIn';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import store from './redux/Store';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import OTPVerification from './components/OTPVerification';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<SplashScreen />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/otpverification' element={<OTPVerification/>}/>
          {/* Private Routes */}
          <Route path="/dashboard/*" element={<PrivateRoute element={Dashboard} />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
