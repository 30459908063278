import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Grid,
  Paper,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CardMedia,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  useCreateBusinessMutation,
  useGetCategoriesQuery,
} from "../redux/features/Business/BusinessApiSlice";

const CreateBusiness = () => {
  const [business, setBusiness] = useState({
    name: "",
    address: "",
    rating: "",
    distance: "0",
    noOfPeopleRated: "",
    optedCategories: [],
    services: [
      {
        name: "",
        businessServiceData: [{ name: "", price: "", offeredPrice: "" }],
      },
    ],
    latitude: "",
    longitude: "",
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [serviceFiles, setServiceFiles] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading

  const navigate = useNavigate(); // Hook for redirection

  const {
    data,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useGetCategoriesQuery();
  const categories = data?.categories || [];

  const [
    createBusiness,
    { isLoading: isCreating, isError: isCreateError },
  ] = useCreateBusinessMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusiness({ ...business, [name]: value });

    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => {
        const { [name]: removedError, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleDynamicChange = (e, index, type, subIndex = null, subType = null) => {
    const { name, value } = e.target;
    const newDynamicData = [...business[type]];

    if (subIndex !== null) {
      newDynamicData[index][subType][subIndex][name] = value;
      const dynamicErrorKey = `${subType}-${index}-${subIndex}-${name}`;
      if (validationErrors[dynamicErrorKey]) {
        setValidationErrors((prevErrors) => {
          const { [dynamicErrorKey]: removedError, ...rest } = prevErrors;
          return rest;
        });
      }
    } else {
      newDynamicData[index][name] = value;
      const dynamicErrorKey = `${type}-${index}-${name}`;
      if (validationErrors[dynamicErrorKey]) {
        setValidationErrors((prevErrors) => {
          const { [dynamicErrorKey]: removedError, ...rest } = prevErrors;
          return rest;
        });
      }
    }

    setBusiness({ ...business, [type]: newDynamicData });
  };

  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];

    if (file) {
      if (type === "image") {
        setImagePreview(file);
      } else if (type === "video") {
        setVideoPreview(file);
      }
    }
  };

  const handleServiceFileUpload = (e, index, subIndex) => {
    const file = e.target.files[0];
    if (file) {
      setServiceFiles((prevFiles) => ({
        ...prevFiles,
        [`service-${index}-${subIndex}`]: file,
      }));
    }
  };

  const handleCategoryChange = (event, category) => {
    const isChecked = event.target.checked;
    const newSelectedCategories = isChecked
      ? [...selectedCategories, category]
      : selectedCategories.filter((c) => c._id !== category._id);

    setSelectedCategories(newSelectedCategories);

    if (validationErrors.optedCategories) {
      setValidationErrors((prevErrors) => {
        const { optedCategories, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const addField = (type, index = null, subType = null) => {
    const newDynamicData = [...business[type]];

    if (subType) {
      newDynamicData[index][subType].push({
        name: "",
        price: "",
        offeredPrice: "",
        preview: null,
      });
    } else {
      newDynamicData.push({
        name: "",
        businessServiceData: [
          { name: "", price: "", offeredPrice: "", preview: null },
        ],
      });
    }

    setBusiness({ ...business, [type]: newDynamicData });
  };

  const removeField = (type, index, subType = null, subIndex = null) => {
    const newDynamicData = [...business[type]];

    if (subType) {
      newDynamicData[index][subType].splice(subIndex, 1);
    } else {
      newDynamicData.splice(index, 1);
    }

    setBusiness({ ...business, [type]: newDynamicData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!business.name) errors.name = "Business Name is required";
    if (!business.address) errors.address = "Address is required";
    if (!business.rating) errors.rating = "Rating is required";
    if (!business.distance) errors.distance = "Distance is required";
    if (!business.noOfPeopleRated)
      errors.noOfPeopleRated = "Number of People Rated is required";
    if (!business.latitude) errors.latitude = "Latitude is required";
    if (!business.longitude) errors.longitude = "Longitude is required";
    if (selectedCategories.length === 0)
      errors.optedCategories = "At least one category must be selected";

    business.services.forEach((service, index) => {
      if (!service.name)
        errors[`services-${index}-name`] = `Service Name #${index + 1} is required`;

      service.businessServiceData.forEach((serviceData, subIndex) => {
        if (!serviceData.name)
          errors[`businessServiceData-${index}-${subIndex}-name`] = `Service Data Name #${index + 1}-${subIndex + 1} is required`;
        if (!serviceData.price)
          errors[`businessServiceData-${index}-${subIndex}-price`] = `Service Data Price #${index + 1}-${subIndex + 1} is required`;
        if (!serviceData.offeredPrice)
          errors[`businessServiceData-${index}-${subIndex}-offeredPrice`] = `Service Data Offered Price #${index + 1}-${subIndex + 1} is required`;
      });
    });

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setLoading(true); // Set loading to true when submission starts

    try {
      const businessData = {
        ...business,
        optedCategories: selectedCategories.map((c) => ({
          categoryId: c._id,
        })),
      };

      const response = await createBusiness(businessData).unwrap();

      const formData = new FormData();
      if (imagePreview) {
        formData.append("image", imagePreview);
      }
      if (videoPreview) {
        formData.append("video", videoPreview);
      }

      Object.keys(serviceFiles).forEach((key) => {
        formData.append(key, serviceFiles[key]);
      });

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}upload-business-files/${response.business._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoading(false); // Set loading to false after submission completes
      navigate("/get-business"); // Redirect to GetBusinessComponent after success
    } catch (error) {
      console.error("Failed to create business:", error);
      setLoading(false); // Set loading to false if there's an error
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const ratingOptions = [];
  for (let i = 1.1; i <= 4.9; i += 0.1) {
    ratingOptions.push(i.toFixed(1));
  }

  return (
    <Container maxWidth="md">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4, mb: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ fontWeight: "bold", color: "#681C7C" }}
        >
          Create New Business
        </Typography>

        {/* Overlay Loading Animation */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Paper
          sx={{
            padding: 4,
            marginBottom: 4,
            borderRadius: 3,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            background: "linear-gradient(to bottom right, #ffffff, #f9f9f9)",
            border: `1px solid #e0e0e0`,
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", color: "#681C7C" }}>
            Business Information
          </Typography>
          <TextField
            fullWidth
            label="Business Name"
            name="name"
            margin="normal"
            value={business.name}
            onChange={handleInputChange}
            variant="outlined"
            error={!!validationErrors.name}
            helperText={validationErrors.name}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Address"
            name="address"
            margin="normal"
            value={business.address}
            onChange={handleInputChange}
            variant="outlined"
            error={!!validationErrors.address}
            helperText={validationErrors.address}
            sx={{ mb: 2 }}
          />

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!validationErrors.rating}
                sx={{ mb: 2 }}
              >
                <InputLabel>Rating</InputLabel>
                <Select
                  name="rating"
                  value={business.rating}
                  onChange={handleInputChange}
                  label="Rating"
                >
                  {ratingOptions.map((rating) => (
                    <MenuItem key={rating} value={rating}>
                      {rating}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.rating && (
                  <Typography color="error">
                    {validationErrors.rating}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of People Rated"
                name="noOfPeopleRated"
                margin="normal"
                value={business.noOfPeopleRated}
                onChange={handleInputChange}
                variant="outlined"
                error={!!validationErrors.noOfPeopleRated}
                helperText={validationErrors.noOfPeopleRated}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Latitude"
                name="latitude"
                margin="normal"
                value={business.latitude}
                onChange={handleInputChange}
                variant="outlined"
                error={!!validationErrors.latitude}
                helperText={validationErrors.latitude}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Longitude"
                name="longitude"
                margin="normal"
                value={business.longitude}
                onChange={handleInputChange}
                variant="outlined"
                error={!!validationErrors.longitude}
                helperText={validationErrors.longitude}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 3, fontWeight: "bold", color: "#681C7C" }}>
            Image Upload
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                startIcon={<CloudUploadIcon />}
                sx={{
                  background: "linear-gradient(to right, #681C7C, #9141AC)",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  fontWeight: "bold",
                  boxShadow: "0px 4px 12px rgba(104, 28, 124, 0.4)",
                  "&:hover": {
                    background: "linear-gradient(to right, #5A175F, #702D8C)",
                  },
                }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "image")}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={8}>
              {imagePreview && (
                <CardMedia
                  component="img"
                  image={URL.createObjectURL(imagePreview)}
                  alt="Uploaded Image Preview"
                  sx={{ height: 300, borderRadius: 2, boxShadow: 1 }}
                />
              )}
            </Grid>
          </Grid>

          <Typography variant="subtitle1" gutterBottom sx={{ mt: 3, fontWeight: "bold", color: "#681C7C" }}>
            Video Upload
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                startIcon={<CloudUploadIcon />}
                sx={{
                  background: "linear-gradient(to right, #681C7C, #9141AC)",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  fontWeight: "bold",
                  boxShadow: "0px 4px 12px rgba(104, 28, 124, 0.4)",
                  "&:hover": {
                    background: "linear-gradient(to right, #5A175F, #702D8C)",
                  },
                }}
              >
                Upload Video
                <input
                  type="file"
                  hidden
                  accept="video/*"
                  onChange={(e) => handleFileUpload(e, "video")}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={8}>
              {videoPreview && (
                <video
                  controls
                  src={URL.createObjectURL(videoPreview)}
                  style={{
                    width: "100%",
                    height: 300,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Paper>

        <Paper
          sx={{
            padding: 4,
            marginBottom: 4,
            borderRadius: 3,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            background: "linear-gradient(to bottom right, #ffffff, #f9f9f9)",
            border: `1px solid #e0e0e0`,
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", color: "#681C7C" }}>
            Opted Categories
          </Typography>
          {categoriesLoading ? (
            <Typography>Loading categories...</Typography>
          ) : categoriesError ? (
            <Typography color="error">Failed to load categories.</Typography>
          ) : categories.length > 0 ? (
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              error={!!validationErrors.optedCategories}
            >
              <InputLabel>Categories</InputLabel>
              <Select
                multiple
                value={selectedCategories.map((c) => c.name)}
                renderValue={(selected) => selected.join(", ")}
                label="Categories"
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    <Checkbox
                      checked={selectedCategories.some(
                        (c) => c._id === category._id
                      )}
                      onChange={(e) => handleCategoryChange(e, category)}
                    />
                    <ListItemAvatar>
                      <Avatar src={category.image} />
                    </ListItemAvatar>
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
              </Select>
              {validationErrors.optedCategories && (
                <Typography color="error">
                  {validationErrors.optedCategories}
                </Typography>
              )}
            </FormControl>
          ) : (
            <Typography>No categories available.</Typography>
          )}
        </Paper>

        <Paper
          sx={{
            padding: 4,
            marginBottom: 4,
            borderRadius: 3,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            background: "linear-gradient(to bottom right, #ffffff, #f9f9f9)",
            border: `1px solid #e0e0e0`,
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", color: "#681C7C" }}>
            Services
          </Typography>
          {business.services.map((service, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
              sx={{ boxShadow: 1, mb: 2, "&:before": { display: "none" } }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#681C7C" }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  backgroundColor: "#fafafa",
                  borderBottom: "1px solid #e0e0e0",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                <Typography sx={{ fontWeight: "bold", color: "#681C7C" }}>{`Service Name #${index + 1}`}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 2 }}>
                <TextField
                  fullWidth
                  label={`Service Name #${index + 1}`}
                  name="name"
                  margin="normal"
                  value={service.name}
                  onChange={(e) => handleDynamicChange(e, index, "services")}
                  variant="outlined"
                  error={!!validationErrors[`services-${index}-name`]}
                  helperText={validationErrors[`services-${index}-name`]}
                  sx={{ mb: 2 }}
                />
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "bold", color: "#681C7C" }}>
                  Service Data
                </Typography>
                {service.businessServiceData &&
                  service.businessServiceData.map((serviceData, subIndex) => (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      key={subIndex}
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Service Name"
                          name="name"
                          margin="normal"
                          value={serviceData.name}
                          onChange={(e) =>
                            handleDynamicChange(
                              e,
                              index,
                              "services",
                              subIndex,
                              "businessServiceData"
                            )
                          }
                          variant="outlined"
                          error={
                            !!validationErrors[
                              `businessServiceData-${index}-${subIndex}-name`
                            ]
                          }
                          helperText={
                            validationErrors[
                              `businessServiceData-${index}-${subIndex}-name`
                            ]
                          }
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label="Price"
                          name="price"
                          margin="normal"
                          value={serviceData.price}
                          onChange={(e) =>
                            handleDynamicChange(
                              e,
                              index,
                              "services",
                              subIndex,
                              "businessServiceData"
                            )
                          }
                          variant="outlined"
                          error={
                            !!validationErrors[
                              `businessServiceData-${index}-${subIndex}-price`
                            ]
                          }
                          helperText={
                            validationErrors[
                              `businessServiceData-${index}-${subIndex}-price`
                            ]
                          }
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label="Offered Price"
                          name="offeredPrice"
                          margin="normal"
                          value={serviceData.offeredPrice}
                          onChange={(e) =>
                            handleDynamicChange(
                              e,
                              index,
                              "services",
                              subIndex,
                              "businessServiceData"
                            )
                          }
                          variant="outlined"
                          error={
                            !!validationErrors[
                              `businessServiceData-${index}-${subIndex}-offeredPrice`
                            ]
                          }
                          helperText={
                            validationErrors[
                              `businessServiceData-${index}-${subIndex}-offeredPrice`
                            ]
                          }
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <IconButton
                          onClick={() =>
                            addField("services", index, "businessServiceData")
                          }
                        >
                          <AddIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            removeField(
                              "services",
                              index,
                              "businessServiceData",
                              subIndex
                            )
                          }
                          disabled={service.businessServiceData.length === 1}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <Button
                              variant="contained"
                              component="label"
                              fullWidth
                              startIcon={<CloudUploadIcon />}
                              sx={{
                                background: "linear-gradient(to right, #681C7C, #9141AC)",
                                borderRadius: "50px",
                                padding: "10px 20px",
                                fontWeight: "bold",
                                boxShadow: "0px 4px 12px rgba(104, 28, 124, 0.4)",
                                "&:hover": {
                                  background: "linear-gradient(to right, #5A175F, #702D8C)",
                                },
                              }}
                            >
                              Upload Image
                              <input
                                type="file"
                                hidden
                                onChange={(e) =>
                                  handleServiceFileUpload(e, index, subIndex)
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            {serviceFiles[`service-${index}-${subIndex}`] && (
                              <CardMedia
                                component="img"
                                image={URL.createObjectURL(
                                  serviceFiles[`service-${index}-${subIndex}`]
                                )}
                                alt="Service Image"
                                sx={{
                                  height: 150,
                                  width: 150,
                                  borderRadius: 2,
                                  boxShadow: 1,
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => addField("services")}
            startIcon={<AddIcon />}
            sx={{
              background: "linear-gradient(to right, #681C7C, #9141AC)",
              borderRadius: "50px",
              padding: "10px 20px",
              fontWeight: "bold",
              boxShadow: "0px 4px 12px rgba(104, 28, 124, 0.4)",
              "&:hover": {
                background: "linear-gradient(to right, #5A175F, #702D8C)",
              },
            }}
          >
            Add Another Service
          </Button>
        </Paper>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{
            padding: 2,
            background: "linear-gradient(to right, #681C7C, #9141AC)",
            borderRadius: "50px",
            fontWeight: "bold",
            boxShadow: "0px 4px 12px rgba(104, 28, 124, 0.4)",
            "&:hover": {
              background: "linear-gradient(to right, #5A175F, #702D8C)",
            },
          }}
          disabled={isCreating || loading} // Disable button during loading or creating
        >
          {isCreating || loading ? "Creating..." : "Create Business"}
        </Button>
        {isCreateError && (
          <Typography color="error">
            Failed to create business. Please try again.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default CreateBusiness;
